<main class="main">
    <app-administration-pane></app-administration-pane> 
    <p-splitter [style]="{ height: '100%' }" styleClass="mb-5" [panelSizes]="[11, 89]" >      
      <ng-template pTemplate>
          <div class="menu">
            <app-left-pane></app-left-pane>
          </div>
        </ng-template>        
        <ng-template pTemplate>
          <div class="content">       
            <router-outlet></router-outlet>
          </div>
        </ng-template>       
      </p-splitter> 
      
</main>
