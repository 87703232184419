<p-scrollPanel [style]="{ height: '800px' }">
<div class="card">
    <p-panel [toggleable]="true">
        <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">           
                <span class="font-bold">
                    <strong>Driver iQ Carriers</strong>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="icons">
            <button class="p-panel-header-icon p-link mr-2">
                <span class="pi pi-cog"></span>
            </button>            
        </ng-template>
        <ng-template pTemplate="footer">
            <div class="flex flex-wrap align-items-center justify-content-between gap-3">
                <div class="customFooter">
                    <div class="flex align-items-center gap-2">
                        <p-button icon="pi pi-phone" rounded="true" text="true" />
                        <p-button icon="pi pi-envelope" severity="secondary" rounded="true" text="true" />
                    </div>
                    <div class="refresh">
                        <div>
                            <p-button icon="pi pi-refresh" rounded="true" text="true" />
                        </div>
                        <div>
                            <h4>Updated 2 hours ago.</h4>
                        </div>                    
                    </div>
                </div>
            </div>
        </ng-template>   
        <div class="topContainer">
            <div class="stats clientStats">
                <div class="statsHeader">
                    <div>
                        <h3>Clients</h3>
                    </div>
                    <div>                    
                        <span class="pi pi-truck" style="font-size:larger"></span>
                    </div>
                </div>
                <div class="statCount">
                    <h1>57</h1>
                </div>
            </div>

            <div class="stats eSupervisionStats">
                <div class="statsHeader">
                    <div>
                        <h3>eSupervision</h3>
                    </div>
                    <div>                    
                        <span class="pi pi-id-card" style="font-size:larger"></span>
                    </div>
                </div>
                <div class="statCount">
                    <h1>6</h1>
                </div>
            </div>

            <div class="stats Active_Drivers">
                <div class="statsHeader">
                    <div>
                        <h3>Active Drivers</h3>
                    </div>
                    <div>                    
                        <span class="pi pi-id-card" style="font-size:larger"></span>
                    </div>
                </div>
                <div class="statCount">
                    <h1>7</h1>
                </div>
            </div>

            <div class="stats Has_Accident">
                <div class="statsHeader">
                    <div>
                        <h3>Has Accident</h3>
                    </div>
                    <div>                    
                        <span class="pi pi-id-card" style="font-size:larger"></span>
                    </div>
                </div>
                <div class="statCount">
                    <h1>5</h1>
                </div>
            </div>
            <div class="card flex justify-content-center">
                <p-chart type="doughnut" [data]="data" [options]="options" />
            </div>
        </div>    
    </p-panel>
    <p-panel [toggleable]="true">
        <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">           
                <span class="font-bold">
                    <strong>Motor Vehicle</strong>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="icons">
            <button class="p-panel-header-icon p-link mr-2">
                <span class="pi pi-cog"></span>
            </button>            
        </ng-template>
        <ng-template pTemplate="footer">
            <div class="flex flex-wrap align-items-center justify-content-between gap-3">
                <div class="customFooter">
                    <div class="flex align-items-center gap-2">
                        <p-button icon="pi pi-phone" rounded="true" text="true" />
                        <p-button icon="pi pi-envelope" severity="secondary" rounded="true" text="true" />
                    </div>
                    <div class="refresh">
                        <div>
                            <p-button icon="pi pi-refresh" rounded="true" text="true" />
                        </div>
                        <div>
                            <h4>Updated 2 hours ago.</h4>
                        </div>                    
                    </div>
                </div>
            </div>
        </ng-template> 
        <div class="topContainer">
            <div class="stats clientStats">
                <div class="statsHeader">
                    <div>
                        <h3>TransUnion</h3>
                    </div>
                    <div>                    
                        <span class="pi pi-truck" style="font-size:larger"></span>
                    </div>
                </div>
                <div class="statCount">
                    <h1>6</h1>
                </div>
            </div>

            <div class="stats eSupervisionStats">
                <div class="statsHeader">
                    <div>
                        <h3>Samba (adr)</h3>
                    </div>
                    <div>                    
                        <span class="pi pi-id-card" style="font-size:larger"></span>
                    </div>
                </div>
                <div class="statCount">
                    <h1>5</h1>
                </div>
            </div>
            <div class="card flex justify-content-center">
                <p-chart type="doughnut" [data]="mvrData" [options]="options" />
            </div>
        </div>   
    </p-panel>
</div>
</p-scrollPanel>