import { Route, Router, RouterModule, RouterOutlet } from '@angular/router';
import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { MsalService, MSAL_INSTANCE, MsalModule } from '@azure/msal-angular';
import { AuthenticationResult, BrowserStorage } from '@azure/msal-browser';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../../environments/environment';
import { MSALInstanceFactory } from './../../msal-instance'
import { HomeComponent } from '../home/home.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { Inject } from '@angular/core';
import { DriveriqWebService } from '../driveriq-web.service';
import { TokenService } from '../token-service.service';
import { Token } from '@angular/compiler';
import { tap, map } from 'rxjs/operators';
import { Profile } from './../class/class';
import { name } from '@azure/msal-angular/packageMetadata';


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [ButtonModule, CardModule, RouterOutlet, HomeComponent, FormsModule],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  constructor(private authService : MsalService,
    private router: Router,
    private tokenService: TokenService,
    private diqService : DriveriqWebService) {
    this.authService.initialize();
  }

  async login() {

    sessionStorage.clear()
    localStorage.clear();

    if ('caches' in window) {
      caches.keys().then((keyList) => {
        keyList.forEach((key) => {
          caches.delete(key);
        });
      });
    }
    await this.authService.loginPopup()
      .pipe(
        tap((result: any) => {
          if (!result?.account) {
            throw new Error('No account information received');
          }
        }),
        map((result: any) => {
          // Set active account first
          this.authService.instance.setActiveAccount(result.account);

          // Get and verify token
          const token = this.authService.instance.getActiveAccount()?.idToken;          
          if (!token) {
            throw new Error('Token not received');
          }
          
          // initial update of profile with token and names
          this.tokenService.updateData({name : this.authService.instance.getActiveAccount()?.name ?? '',
            user : this.authService.instance.getActiveAccount()?.username ?? '',
            IsDiqAdmin : false,
            Role: '',
            token : token}
          )                            

          this.diqService.CheckUser().subscribe((user : Profile) =>{

            // 2nd update of profile with Role
            this.tokenService.updateData({name : this.authService.instance.getActiveAccount()?.name ?? '',
                                          user : this.authService.instance.getActiveAccount()?.username ?? '',
                                          IsDiqAdmin : user.IsDiqAdmin,
                                          Role: user.IsDiqAdmin === true ? "Admin" : "User",
                                          token : token}
                                        )                            
          }
        )
          return token;
        }
        )
      )
      .subscribe({
        next: (_) => {
          // Only navigate after token is confirmed set
          this.router.navigateByUrl('/home');
        },
        error: (error) => {
          console.error('Login failed', error);
        }
      });
    }


    isLoggedIn() : boolean {
      if (this.authService.instance.getActiveAccount != null) {
        return true;
      }

      return false;
    }

  }
