/* src/app/login/login.component.scss */
:host ::ng-deep {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
:host ::ng-deep img {
  padding: 10px;
}
:host ::ng-deep .btn-container {
  align-items: center;
}
/*# sourceMappingURL=login.component.css.map */
