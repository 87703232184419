import { routes } from './../app.routes';
import { MenuModule } from 'primeng/menu';
import { RippleModule } from 'primeng/ripple';
import { BadgeModule } from 'primeng/badge';
import { AvatarModule } from 'primeng/avatar';
import { OnInit } from '@angular/core';
import { DriverIqCarrier, MenuItem } from '../Interface/interface';
import { Component } from '@angular/core';
import { PrimeIcons } from 'primeng/api';
import { ProfileComponent } from '../profile/profile.component';
import { CommonModule } from '@angular/common';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';


@Component({
  selector: 'app-administration-pane',
  standalone: true,
  imports: [MenuModule, BadgeModule, RippleModule, AvatarModule, ProfileComponent, CommonModule, RouterModule],
  providers : [provideAnimations()],
  templateUrl: './administration-pane.component.html',
  styleUrl: './administration-pane.component.scss'
})
  export class AdministrationPaneComponent {
    items: MenuItem[] | undefined;

    constructor(private router : Router)
    {
    }
   
}
