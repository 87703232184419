import { provideRouter } from '@angular/router';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import  routes from './app/app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor } from './http-interceptor';

bootstrapApplication(AppComponent, 
                     {
                     providers: [
                      provideRouter(routes),
                      provideAnimations(),
                      provideHttpClient(withInterceptors([authInterceptor]))
                    ] 
                  }
).catch(err => console.error(err));

