import { Routes } from '@angular/router';
import { ClientcardComponent } from './client/client.component';
import { AdministrationPaneComponent } from './administration-pane/administration-pane.component';
import { ClientlistComponent } from './clientlist/clientlist.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { maslGuard } from './masl.guard';

export const routes: Routes = [

    {
        path : '', component : LoginComponent
    },
    {
        path : 'login', component : LoginComponent
    },
    {path: 'home', component : HomeComponent, title :"Home",
        children : [
            {path: '', component : DashboardComponent, title :"Dashboard"},  /*this forces the home to route to dashboard */
            {path: 'dashboard-component', component : DashboardComponent, title :"Dashboard"},
            {path : 'clientList-component',
                loadComponent : () => import('./clientlist/clientlist.component').then(c=> c.ClientlistComponent) , 
                canActivate: [maslGuard]
            }                    
               
        ]
    },   
    { path: '', redirectTo: 'home', pathMatch: 'full' }
];

export default routes;
