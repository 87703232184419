<div class="card flex justify-content-center">
    <p-button (onClick)="op.toggle($event)" icon="pi pi-sign-out" />
    <p-overlayPanel #op>
        <div class="flex flex-column gap-3 w-25rem">
            <div>
                <span class="font-medium text-900 block mb-2"></span>
                <ul class="list-none p-0 m-0 flex flex-column gap-3">
                    <li class="flex align-items-center gap-2">
                       <i class="pi pi-user"></i>
                        <div>
                            <span class="font-medium">{{ profile.name }}</span>
                            <div class="text-sm text-color-secondary">{{ profile.user }}</div>
                        </div>
                        <div class="flex align-items-center gap-2 text-color-secondary ml-auto text-sm">
                            <span>{{ profile.Role }}</span>                            
                        </div>
                        <div>
                            <p-button label="Sign Out" icon="pi pi-sign-out" size="small" (onClick)="logOut()"/>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </p-overlayPanel>
</div>