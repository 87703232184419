import { User } from './../Interface/interface';
import { Component, Input, OnInit } from '@angular/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ChipsModule } from 'primeng/chips';
import { CommonModule } from '@angular/common';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MsalService, MSAL_INSTANCE, MsalModule } from '@azure/msal-angular';
import { MSALInstanceFactory} from './../../msal-instance'
import { RouterModule, Router, RouterOutlet } from '@angular/router';
import { msalConfig } from '../auth-config';
import { environment } from '../../environments/environment';
import { TokenService } from '../token-service.service';
import { Profile } from '../class/class';


@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [OverlayPanelModule,
            InputGroupModule,
            InputGroupAddonModule,
            ButtonModule,
            InputTextModule,
            ChipsModule,
            CommonModule,
            ButtonModule,
            RouterModule],

  providers: [provideAnimations(),
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService], 
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'  
})
export class ProfileComponent implements OnInit {
  constructor(private authService : MsalService,
              private router : Router,
              private tokenService : TokenService) {
    this.authService.initialize();   
  }

profile : Profile = new Profile('','', false, '','');

ngOnInit(): void {  
  this.tokenService.profileSubject$.subscribe((p : Profile) => {
  this.profile.name = p.name;
  this.profile.IsDiqAdmin = p.IsDiqAdmin;
  this.profile.user = p.user;
  this.profile.token = p.token;
  this.profile.Role = p.Role
})
}
  async logOut(): Promise<void> {        
  
    await this.authService.logout(
      {  
        postLogoutRedirectUri: environment.url,                       
      }      
    ); 
  }  
}
