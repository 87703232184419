import { Component } from '@angular/core';
import { RouterOutlet, RouterModule } from '@angular/router';
import { AdministrationPaneComponent } from "./administration-pane/administration-pane.component";
import { LeftPaneComponent } from "./left-pane/left-pane.component";
import { SplitterModule } from 'primeng/splitter';
import { MsalModule, MsalService } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../environments/environment';
import { LoginComponent } from "./login/login.component";
import { HomeComponent } from './home/home.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet,
            AdministrationPaneComponent,
            RouterModule,
            LeftPaneComponent,
            SplitterModule,
            MsalModule, 
            LoginComponent,
            HomeComponent],            
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})


export class AppComponent {
  title = 'DriverIQWeb';
}
