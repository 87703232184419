/* src/app/dashboard/dashboard.component.scss */
:host ::ng-deep .p-panel {
  margin-bottom: 1rem;
}
:host ::ng-deep .customFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -10px;
}
:host ::ng-deep .refresh {
  display: flex;
  align-items: center;
}
.topContainer {
  display: flex;
  gap: 20px;
  align-items: center;
}
.topContainer .stats {
  border-radius: 20px;
  background-color: rgb(207, 240, 207);
  height: 250px;
  width: 200px;
}
.topContainer .stats .statsHeader {
  display: flex;
  justify-content: space-between;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  border: 1px solid lightgray;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #ffffff;
}
.topContainer .stats .statCount {
  font-size: xx-large;
  text-align: center;
}
/*# sourceMappingURL=dashboard.component.css.map */
