/* src/app/app.component.scss */
:host ::ng-deep .p-button {
  font-size: 20px;
}
:host ::ng-deep .p-button app-administration-pane {
  position: fixed;
  top: -15px;
  width: 100%;
  padding-right: 10px;
}
:host ::ng-deep .p-button app-left-pane {
  top: 55px;
  left: 8px;
  height: 100%;
  padding-top: 18px;
  overflow: auto;
}
/*# sourceMappingURL=app.component.css.map */
