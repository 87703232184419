<div class="layout-wrapper layout-menu-static layout-menu-light layout-topbar-blue">
    <div class="layout-topbar">
        <ul class="nav-list">
            <li>
                <div class="mainImg">
                   <img class="diqImg" src="./assets/DriverIq.svg" alt="Driver Iq Logo"/>
                </div>                
            </li>   
            <li class="signOut">
                <app-profile></app-profile>
            </li>   
        </ul>
    </div>    
</div>