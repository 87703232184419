import { Component } from '@angular/core';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import {PanelModule} from 'primeng/panel';
import { ChartModule } from 'primeng/chart';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ScrollTopModule } from 'primeng/scrolltop';


@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [PanelModule, AvatarModule, ButtonModule, ChartModule, ScrollPanelModule, ScrollTopModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {

  data: any;
  options: any;
  mvrData : any;


  ngOnInit() {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue('--text-color');

      this.data = {
          labels: ['Clients', 'eSupervision', 'Active Drivers', 'Has Accident'],
          datasets: [
              {
                  data: [57, 6, 7, 5],
                  backgroundColor: [documentStyle.getPropertyValue('--blue-500'),
                                    documentStyle.getPropertyValue('--yellow-500'), 
                                    documentStyle.getPropertyValue('--green-500'), 
                                    documentStyle.getPropertyValue('gray')],
                  hoverBackgroundColor: [documentStyle.getPropertyValue('--blue-400'), 
                                         documentStyle.getPropertyValue('--yellow-400'), 
                                         documentStyle.getPropertyValue('--green-400'),
                                         documentStyle.getPropertyValue('lightgray')]
              }
          ]
      };
      this.options = {
          cutout: '50%',
          plugins: {
              legend: {
                  labels: {
                      color: textColor
                  }
              }
          }
      };

      this.mvrData = {
        labels: ['TransUnion', 'Samba (adr)'],
        datasets: [
            {
                data: [6, 5],
                backgroundColor: [documentStyle.getPropertyValue('--blue-500'),
                                  documentStyle.getPropertyValue('--yellow-500')],
                hoverBackgroundColor: [documentStyle.getPropertyValue('--blue-400'),
                                       documentStyle.getPropertyValue('--yellow-500')]
            }
        ]
    };
    
  }
}
