import { routes } from './../app.routes';
import { MenuModule } from 'primeng/menu';
import { OnInit } from '@angular/core';
import { DriverIqCarrier, MenuItem } from '../Interface/interface';
import { Component } from '@angular/core';
import { PrimeIcons } from 'primeng/api';
import { ProfileComponent } from '../profile/profile.component';
import { CommonModule } from '@angular/common';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';


@Component({
  selector: 'app-left-pane',
  standalone: true,
  imports: [MenuModule],
  templateUrl: './left-pane.component.html',
  styleUrl: './left-pane.component.scss'
})
export class LeftPaneComponent implements OnInit {
  items: MenuItem[] |undefined; 

    constructor(private router : Router)
    {
    }

    driverIqCarrier : DriverIqCarrier = {
      ID : 1,
      DIQ_Code : "DIQ001",
      CompanyID : "123456",
      DOTNumber : "654321",
      ClientID : "ABCY99",
      SupervisionCode : "1479",
      SupervisionDirectory : "//netfiler2/Fakedirectory/",
      SupervisionMVRAccount : "01",
      SupervisionActivated : "2024-01-01",
      SupervisionDeactivated : "",
      SupervisionAssociateCode : "",
      SupervisionOptions: "",
      LastFeedCompleted : new Date(),
      LastFeedreceived : new Date(), 
      HasAccident : "",
      HasDrugAlc : "",
      SinglePositionPriorTo : new Date(), 
      EmpHistoryFrom :new Date(),
      AccHistoryFrom : new Date(),
      DAHistoryFrom : new Date(),
    ContactMessage : "",
    ContactCUUID : "",
    DA_FaxTo : "",
    DA_Faxnum : "",
    DA_MailTo : "",
    DA_MailAddressID : 0,
    Accident_Notes : "",
    Active_Drivers : "",
    CarrierPRENotes : "",
    ACCShowYears : 0,
    AccShowPreventable : 0,
    AccShowNonReportable : 0,
    AccShowReportable : 0,
    AccShowLongDesc : 0,
    AccShowShortDesc : 0,
    DA_HandleMethod : "",
    PREYearLimit : 0,
    AccRptYPreY : 0,
    AccRptNPreY : 0,
    AccRptYPreN : 0,
    AccRptNPreN : 0,
    ImportWizard : 0
    }


    ngOnInit() {
      this.items = [
          { label: 'Dashboard', icon: 'pi pi-home' , shortDescription:'Dashboard', tag: '', routerLink: ['dashboard-component']},
          { label: 'Companies', icon: 'pi pi-truck' , shortDescription:'Companies', tag: '', routerLink:['clientList-component']},
          { label: 'Drivers', icon: 'pi pi-user' , shortDescription:'Drivers', tag: '', routerLink:[]},
          { label: 'Reports', icon: 'pi pi-list' , shortDescription:'Reports', tag: '', routerLink:[]},
          { label: 'Settings', icon: 'pi pi-cog' , shortDescription:'Settings', tag: '', routerLink:[]}
      ];        
    }

  }

  

