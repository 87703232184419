/* src/app/administration-pane/administration-pane.component.scss */
.layout-topbar {
  background-color: #a1d4bb;
  border-radius: 5px;
}
.signOut {
  margin-right: -10px;
}
.nav-list {
  display: flex;
  justify-content: space-between;
  margin-right: 1em;
  list-style-type: none;
  align-items: center;
  padding-left: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
}
/*# sourceMappingURL=administration-pane.component.css.map */
