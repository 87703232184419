import { Component, OnInit } from '@angular/core';
import { Route, RouterModule, Router } from '@angular/router';
import { SplitterModule } from 'primeng/splitter';
import { AdministrationPaneComponent } from '../administration-pane/administration-pane.component';
import { LeftPaneComponent } from '../left-pane/left-pane.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [SplitterModule, RouterModule, AdministrationPaneComponent, LeftPaneComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  constructor(private router : Router){    
  }
  
  redirectToTarget() {
    this.router.navigate(['/dashboard-component']);
  }

}
