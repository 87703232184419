import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from './token-service.service';
import { Profile } from './class/class';
export const maslGuard: CanActivateFn = (route, state) => {
  const tokenService = inject(TokenService);
  const router = inject(Router)
  let token : string = ""; 

  tokenService.profileSubject$.subscribe((p : Profile) => {token = p.token}); 
  if (token) {
    return true;
  } 
    else {
      router.navigate(['/login']);
      return false;
  }
};
