import { HttpInterceptorFn, HttpRequest, HttpHandlerFn, HttpEvent } from '@angular/common/http';
import { finalize, Observable, tap} from 'rxjs';
import { TokenService } from './app/token-service.service';
import { inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { LoadingSpinnerServiceService } from './app/loading-spinner-service.service';
import { Profile } from './app/class/class';

export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
 
  let shouldShowSpinner : boolean = false;
  const tokenService = inject(TokenService); 
  const spinnerService = inject(LoadingSpinnerServiceService);
  let token : string = ""; 

  tokenService.profileSubject$.subscribe((p : Profile) => {token = p.token}); 
  
  const clonedRequest = req.clone({
    headers: req.headers.set('Authorization', `Bearer ${token}`)  
    
  });

shouldShowSpinner = req.url.includes('DriverIqCarrier_WithActiveSupervision') || req.url.includes('DriverIqCarrier_GetBySearchBy') ? true : false;

 if (shouldShowSpinner){
     spinnerService.show(); 
 }
 return next(clonedRequest) 
  .pipe(
    finalize (() => (spinnerService.hide()))
    )
};
