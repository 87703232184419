
<div class="card flex justify-content-center">
    <p-card [style]="{ width: '400px' }">
        <ng-template pTemplate="header">
            <img alt="Card" src="../../assets/DriverIq.svg" />
        </ng-template>
        <p>
            Employees are responsible for keeping their login credentials secure and reporting 
            suspicious activity to IT. They should also use strong passwords, update security 
            software regularly, and protect their devices from unauthorized access.
        </p>
        <ng-template pTemplate="footer">
            <div class="btn-container">       
                <p-button label="Log In" class="w-full" styleClass="w-full" (onClick)="login()" />                
            </div>            
        </ng-template>
    </p-card>        
</div>


